import type { FlowType } from '@/declarations/flow';
import { MessageTemplateButtonType } from '@/declarations/messageTemplate.d';
import type { Node } from 'reactflow';

export type Step = {
  id: string;
  title: string;
  type: StepType;
  contents?: any[];
};

export type StepNode = Node<Step>;

export type StepType =
  | 'send-form'
  | 'starting'
  | 'action'
  | 'randomizer'
  | 'message'
  | 'template'
  | 'smart-delay'
  | 'condition'
  | 'start-flow'
  | 'round-robin';

export enum ContentType {
  TEXT = 'text',
  IMAGE = 'image',
  DOCUMENT = 'document',
  VIDEO = 'video',
  AUDIO = 'audio',
  TEMPLATE = 'template',
}

export enum ButtonTypes {
  WEBSITE = MessageTemplateButtonType.URL,
  CALL_OUT = MessageTemplateButtonType.PHONE_NUMBER,
  MESSAGE = 'message',
  ACTION = 'action',
  START_FLOW = 'start-flow',
  GO_TO_STEP = 'go-to-step',
}

export type ActionType =
  | 'add-tag'
  | 'remove-tag'
  | 'add-assignee'
  | 'add-collaborator'
  | 'remove-assignee'
  | 'add-attribute'
  | 'update-attribute'
  | 'wait-reply'
  | 'send-message'
  | 'round-robin-steps'
  | 'opt-in'
  | 'opt-out'
  | 'close-conversation'
  | 'assign-inbox-tab'
  | 'send-waba-template'
  | 'webhook';

export type StartFlowType = 'add-flow';
export type RandomizeType = 'randomizer';
export type SendFormType = 'send-form';
export type SmartDelayType = 'smart-delay';
export type ConditionType = 'condition';
export enum TriggerType {
  KEYWORD = 'keyword',
  WHATSAPP_LINK = 'whatsapp-link',
  EVENT_TRIGGER = 'event-trigger',
  WEBHOOK = 'webhook-trigger',
}

export type TriggerMessageConditon = 'is' | 'contain' | 'contain-all' | 'begin-with' | 'end-with';

export type ITrigger = IKeywordTrigger | IWhatsAppLinkTrigger | IEventTrigger | IWebhookTrigger;

export type IAction =
  | IAddTagAction
  | IRemoveTagAction
  | IAddAssigneeAction
  | IAddCollaboratorAction
  | IRemoveAssigneeAction
  | ISaveAttributeAction
  | IUpdateAttributeAction
  | IWaitForReplyAction
  | ISendMessageAction
  | ISendTemplateMessageAction
  | IOptInAction
  | IOptOutAction
  | IAssignInboxTabAction
  | ICloseConversationAction
  | IWebhookAction;

export type IStartFlow = IAddFlowAction;

export type IRandomizer = IRandomizerAction;

export type ISendForm = ISendFormAction;

export type ISmartDelay = ISmartDelayAction;

export type ICondition = IConditionAction;

export type IContent =
  | IImageContent
  | ITextContent
  | IDocumentContent
  | IVideoContent
  | IAudioContent
  | ITemplateContent;

export type IMessageButton =
  | IMessageButtonGeneric
  | IMessageWebsiteButton
  | IMessageCallButton
  | IMessageStepButton
  | IMessageMessageButton
  | IMessageActionButton
  | IMessageStartFlowButton;

export interface IMessageButtonGeneric {
  id: string;
  title: string;
  portType: string;
  type: '';
  clicked: number;
}

export interface IMessageWebsiteButton extends IMessageButtonGeneric {
  type: ButtonTypes.WEBSITE;
  url: string;
}

export interface IMessageCallButton extends IMessageButtonGeneric {
  type: ButtonTypes.CALL_OUT;
  phone_no: number | string;
}

export interface IMessageStepButton extends IMessageButtonGeneric {
  type: ButtonTypes.GO_TO_STEP;
}

export interface IMessageMessageButton extends IMessageButtonGeneric {
  type: ButtonTypes.MESSAGE;
}

export interface IMessageActionButton extends IMessageButtonGeneric {
  type: ButtonTypes.ACTION;
}

export interface IMessageStartFlowButton extends IMessageButtonGeneric {
  type: ButtonTypes.START_FLOW;
}

export interface IActionContent {
  tags: string[];
}

export interface IMessageContent {
  text: string;
  buttons?: IMessageButton[];
}

export interface IKeywordTrigger {
  id: string;
  type: TriggerType.KEYWORD;
  data: {
    condition: TriggerMessageConditon;
    keywords: number[];
  };
}

export interface IWhatsAppLinkTrigger {
  id: string;
  type: TriggerType.WHATSAPP_LINK;
  data: {
    label: string;
    phone_number: string | number;
    message: string;
  };
}

export interface IEventTrigger {
  id: string;
  type: TriggerType.EVENT_TRIGGER;
  data: {
    label: string;
    phone_number: string;
    message: string;
  };
}

export interface IWebhookTrigger {
  id: string;
  type: TriggerType.WEBHOOK;
  data: {
    attributes: { attributeId: number | undefined; mappingAttribute: string; id: string }[];
  };
}
export interface IWaitForReplyAction {
  id: string;
  type: 'wait-reply';
  data: {};
}

export interface IRemoveAssigneeAction {
  id: string;
  type: 'remove-assignee';
  data: {
    assignee: string[];
  };
}
export interface IAddAssigneeAction {
  id: string;
  type: 'add-assignee';
  data: {
    assignee: string[];
  };
}

export interface IAddCollaboratorAction {
  id: string;
  type: 'add-collaborator';
  data: {
    collaborators: number[];
  };
}

export interface IAssignInboxTabAction {
  id: string;
  type: 'assign-inbox-tab';
  data: {
    tab: string[];
  };
}

export interface ICloseConversationAction {
  id: string;
  type: 'close-conversation';
}

export interface IWebhookAction {
  id: string;
  type: 'webhook';
  data: {
    url: string;
    method: 'GET' | 'POST';
    headers: { value: string | undefined; paramField: string; id: string }[];
    params: {
      value: string | undefined;
      paramField: string;
      id: string;
      valueType: 'text' | 'params';
    }[];
    attributes: { attributeId: number | undefined; mappingAttribute: string; id: string }[];
    response: { type: 'res-success' | 'res-fail'; id: string }[];
  };
}

export interface ISaveAttributeAction {
  id: string;
  type: 'add-attribute';
  data: {
    attributes: number[];
  };
}

export interface IUpdateAttributeAction {
  id: string;
  type: 'update-attribute';
  data: {
    attributes: { attributeId: number; value: string }[];
  };
}

export interface ISendMessageAction {
  id: string;
  type: 'send-message';
  data: {
    content: string;
    phone_no: string;
  };
}

export interface ISendTemplateMessageAction {
  id: string;
  type: 'send-waba-template';
  data: {
    phone_no: string;
    fbid: string;
    buttons: { id: string }[];
    components: { type: 'header' | 'body' | 'buttons'; parameters: string[] }[];
  };
}

export interface IOptInAction {
  id: string;
  type: FlowType.OPT_IN;
}
export interface IOptOutAction {
  id: string;
  type: FlowType.OPT_OUT;
}
export interface IAddTagAction {
  id: string;
  type: 'add-tag';
  data: {
    tags: string[];
  };
}

export interface IRandomizerAction {
  id: string;
  type: 'randomizer';
  data: {
    chances: { action_type: string; probability: number }[];
    response: { type: string; id: string }[];
  };
}

export interface IAddFlowAction {
  id: string;
  type: 'add-flow';
  data: {
    flow: {
      title: string;
      id: number;
    };
  };
}

export interface ISendFormAction {
  id: string;
  type: 'send-form';
  data: {
    message: string;
    form: number;
  };
}

export interface IRemoveTagAction {
  id: string;
  type: 'remove-tag';
  data: {
    tags: string[];
  };
}

export interface IImageContent {
  id: string;
  type: 'image';
  data: {
    mime?: string;
    filename: string;
    url: string;
  };
}

export interface ITextContent {
  id: string;
  type: 'text';
  data: IMessageContent;
}

export interface IDocumentContent {
  id: string;
  type: 'document';
  data: {
    mime?: string;
    filename: string;
    url: string;
  };
}
export interface IVideoContent {
  id: string;
  type: 'video';
  data: {
    mime?: string;
    filename: string;
    url: string;
  };
}

export interface IAudioContent {
  id: string;
  type: 'audio';
  data: {
    mime?: string;
    filename: string;
    url: string;
  };
}

export interface ITemplateContent {
  id: string;
  type: 'template';
  data: {
    fbid: string;
    buttons: { id: string }[];
    components: { type: 'header' | 'body' | 'buttons'; parameters: string[] }[];
  };
}

export interface ISmartDelayAction {
  id: string;
  type: 'smart-delay';
  data: {
    duration: string;
    duration_type: 'hour' | 'minute' | 'day';
    response: { type: 'answered' | 'not-answered'; id: string }[];
  };
}

export interface IConditionAction {
  id: string;
  type: 'condition';
  data: {
    multi_conditions: {
      action_type: string;
      conditions: any[];
    }[];
    response: { type: string | 'not-matched'; id: string }[];
  };
}
