import type { INotification } from '@/declarations/notification';
import type { CurrentUser } from '@/declarations/user';
import { getNotifications, markNotificationAsRead } from '@/services/luluchat/notifications';
import { getReminder } from '@/services/luluchat/reminders';
import type { API } from '@/services/luluchat/typings.d';
import { AblySubscription } from '@/utils/ably';
import { useEffect, useMemo } from 'react';
import { useModel, useRequest } from 'umi';
export default () => {
  const { initialState, setInitialState } = useModel('@@initialState');
  const { currentUser = {} as CurrentUser } = initialState || {};
  const { openReminderFormModal, setCurrentReminder } = useModel('reminder');
  const { showChatDetails, reloadFetchGetChatroom } = useModel('inbox');

  const {
    data: notifications = {
      data: [] as INotification[],
    },
    run: fetchGetNotifications,
    loading: isLoadingFetchGetNotifications,
  } = useRequest(getNotifications, {
    manual: true,
    formatResult(res): API.GetNotificationsResult {
      return res;
    },
  });

  const { run: fetchMarkNotificationAsRead, loading: isLoadingMarkNotificationAsRead } = useRequest(
    markNotificationAsRead,
    {
      manual: true,
      formatResult(res) {
        return res;
      },
    },
  );

  const currentUserUUid = useMemo(() => {
    if (!currentUser) return '';
    return currentUser.uuid;
  }, [currentUser]);

  useEffect(async () => {
    if (!currentUserUUid) return;
    const ablyInstance = await AblySubscription.getInstance();
    if (ablyInstance) {
      ablyInstance.subscribeNotification({
        uuid: currentUserUUid,
        onReceivesNotification: (notification: INotification) => {
          console.log('@from ably - notification', notification);
          if (notification?.type) {
            if ('Notification' in window && Notification.permission === 'granted') {
              let title = '';
              let body = '';
              switch (notification?.type) {
                case 'assignment':
                  title = 'Contact Assignment';
                  body = notification?.title;
                  break;
                case 'collab':
                  title = 'Contact Assignment as Collaborator';
                  body = notification?.title;
                  break;
                case 'deals-collab':
                  title = 'Deal Assignment';
                  body = notification?.title;
                  break;
                case 'ticket-collab':
                  title = 'Ticket Assignment';
                  body = notification?.title;
                  break;
                case 'mention':
                  title = 'Mentioned You';
                  body = notification?.title;
                  break;
                case 'reminder':
                  title = 'Reminder: ' + notification?.title;
                  body = notification?.description;
                  break;
              }
              const notificationObj = new Notification(title, {
                body,
                icon: '/favicon.ico',
              });
              notificationObj.onclick = (event) => {
                notificationObj.close();
                event.preventDefault();
                window.open('/inbox/' + notification?.info?.wa_contact_id, '_blank');
              };
            }
            setInitialState((prev) => {
              return {
                ...prev,
                currentUser: {
                  ...prev?.currentUser,
                  unread_notification: (prev?.currentUser.unread_notification || 0) + 1,
                },
              };
            });
          }
        },
      });
    }
  }, [currentUserUUid]);

  const onClickNotification = async (item: INotification) => {
    if (item?.is_read === false) {
      setInitialState((prev) => {
        return {
          ...prev,
          currentUser: {
            ...prev?.currentUser,
            unread_notification: (prev?.currentUser.unread_notification || 0) - 1,
          },
        };
      });
    }
    switch (item.type) {
      case 'reminder':
        const res = await getReminder({ id: item.info.reminder_id });
        if (res?.status) {
          setCurrentReminder(res?.data);
          openReminderFormModal();
        }
        if (
          item?.info?.channel_uuid &&
          localStorage.getItem('x-channel-id') === item?.info?.channel_uuid
        ) {
          showChatDetails(item.info.wa_contact_id);
        }
        break;
      case 'deal':
        if (
          item?.info?.channel_uuid &&
          localStorage.getItem('x-channel-id') !== item?.info?.channel_uuid
        ) {
          localStorage.setItem('x-channel-id', item?.info?.channel_uuid);
        }
        window.location.href = `/deals?pid=${item?.info?.pipeline_uuid}/${item?.info?.card_uuid}`;
        break;
      case 'ticketing':
        if (
          item?.info?.channel_uuid &&
          localStorage.getItem('x-channel-id') !== item?.info?.channel_uuid
        ) {
          localStorage.setItem('x-channel-id', item?.info?.channel_uuid);
        }
        window.location.href = `/tickets?pid=${item?.info?.pipeline_uuid}/${item?.info?.card_uuid}`;
        break;
      case 'collab':
      case 'assignment':
      case 'mention':
        if (
          item?.info?.channel_uuid &&
          localStorage.getItem('x-channel-id') !== item?.info?.channel_uuid
        ) {
          localStorage.setItem('x-channel-id', item?.info?.channel_uuid);
          window.location.href = `/inbox/${item?.info?.wa_contact_id}`;
        } else {
          showChatDetails(item.info.wa_contact_id);
        }
        break;
    }
  };

  return {
    notifications,
    fetchGetNotifications,
    isLoadingFetchGetNotifications,
    fetchMarkNotificationAsRead,
    isLoadingMarkNotificationAsRead,
    onClickNotification,
  };
};
